.app-header-menu .menu .menu-item .menu-link.white-button,
.app-header-menu .menu .menu-item .menu-link .menu-title.white-button {
	color: $black;
}

.app-header-menu
	.menu
	.menu-item
	.menu-link.white-button:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
	border: 1px solid $black;
}

.app-header-menu
	.menu
	.menu-item
	.menu-link.btn-primary:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon),
.app-header-menu
	.menu
	.menu-item
	.menu-link.black-button:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
	border: 1px solid;
}

// Overwrite Theme's Menu Item Classes //
.app-header-menu .menu .menu-item .menu-link.black-button,
.app-header-menu .menu .menu-item .menu-link.btn-primary,
.app-header-menu .menu .menu-item.hover:not(.here) > .menu-link.btn-primary:not(.disabled):not(.active):not(.here),
.app-header-menu .menu .menu-item:not(.here) .menu-link.btn-primary:hover:not(.disabled):not(.active):not(.here),
[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item .menu-link.btn-primary,
[data-kt-app-layout='dark-sidebar']
	.app-sidebar
	.menu
	.menu-item:not(.here)
	.menu-link.menu-here.sidebar-button:hover:not(.disabled):not(.active):not(.here) {
	color: $white;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item:not(.here) .menu-link.menu-here:hover:not(.disabled):not(.active):not(.here) {
	background-color: $primary-light;
	color: $primary;
}
// [data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item.here > .menu-link {
// 	background: $primary;
// }

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item .menu-link.sidebar-button .menu-arrow:after {
	background-color: $white;
}

// [data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item .sidebar-button.menu-link:hover:not(.disabled):not(.active):not(.here) span {
// 	color: $white;
// }

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here),
[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon i {
	color: $primary;
}

[data-kt-app-sidebar-minimize='on'][data-kt-app-layout='dark-sidebar'] .app-sidebar:not(:hover) .menu .menu-item.show:not(.here) > .menu-link {
	background: transparent;
}

.sidebar-button + .sidebar-width {
	width: 245.5px !important;
}
.menu-item .menu-link.btn-primary .menu-arrow {
	position: absolute;
	right: 20px;
	rotate: 90deg;
}

.menu-sub-indention .menu-sub:not([data-popper-placement]) {
	margin-left: 0;
}

/* Large devices (desktops) */
@media only screen and (max-width: 992px) {
	.sidebar-button + .sidebar-width {
		width: auto !important;
		background: $white;
		padding: 0.5rem 0;
	}
}
