//
// Draggable plugin customization: https://shopify.github.io/draggable
//

.draggable {
	transition: opacity 0.3s ease;
	outline: none !important;

	&.draggable-mirror {
		opacity: 0.8;
		transition: opacity 0.3s ease;
		border: 2px dashed var(--kt-gray-300) !important;
		@include border-radius($border-radius);
	}

	&.draggable--original {
		opacity: 0 !important;
	}

	&.draggable-source--is-dragging.draggable--over {
		opacity: 0 !important;
	}

	// Handle
	.draggable-handle {
		cursor: move;
	}
}
