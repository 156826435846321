//============================//
//           MIXINS           //
//============================//

/****** Browser Prefixes ******/
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

/********** Rotate  **********/
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg));
}

/********** Scale ***********/
@mixin scale($scale) {
	@include transform(scale($scale));
}

/******** Translate ********/
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}

/********* Transition *********/
@mixin transition($duration: $basic-transition-time, $timing-function: ease-in-out) {
	-webkit-transition: all $duration $timing-function;
	-moz-transition: all $duration $timing-function;
	-o-transition: all $duration $timing-function;
	transition: all $duration $timing-function;
}

/********* Button **********/
@mixin button($color, $border, $background) {
	color: $color;
	border: $border;
	background: $background;
	padding: 1rem;
	text-decoration: none;
	display: inline-block;
	border-radius: $border-radius-main;
	cursor: pointer;
	@include transition;

	&:hover {
		background: $color;
		color: $background;
		@include transition;
	}
}

/********* Colored Circle *********/
@mixin colored-circle($background-color) {
	width: 20px;
	height: 20px;
	border-radius: $border-radius-circle;
	background-color: $background-color;
}

//============================//
//       GENERAL RULES        //
//============================//
.w-15 {
	width: 15%;
}

.max-vh-50 {
	max-height: 50vh;
}

.max-vh-60 {
	max-height: 60vh;
}

.max-height-200 {
	max-height: 200px;
}

.header-min-height {
	min-height: 5.3rem;
}

.table-title-min-height {
	min-height: 8.2rem;
}
.right-positioning {
	right: 3rem;
}

.rotation-0 {
	@include rotate(0);
	@include transition($basic-transition-time, linear);
}

.rotation-180 {
	@include rotate(180);
	@include transition($basic-transition-time, linear);
}

.overflow-x-scroll {
	overflow-x: scroll;
}

.overflow-y-scroll {
	overflow-y: scroll;
}

.generic-border {
	border: 1px solid $gray-theme-bg-dark;
	border-radius: $border-radius-main;
}

.border-top {
	border-top: 1px solid $gray-theme-bg;
}

.border-bottom {
	border-bottom: 1px solid $gray-theme-bg;
}

.border-left {
	border-left: 1px solid $gray-theme-bg;
}

.border-right {
	border-right: 1px solid $gray-theme-bg;
}

.border-radius-main {
	border-radius: $border-radius-main;
}

.border-radius-large {
	border-radius: $border-radius-large;
}

.border-radius-extra-large {
	border-radius: $border-radius-extra-large;
}

.border-radius-none {
	border-radius: 0 !important;
}

.full-width-textarea {
	background-color: $gray-theme-bg;
	width: 100%;
	border: none;
	border-radius: $border-radius-main;

	&:focus-visible {
		outline: none;
	}
}
.resize-none {
	resize: none;
}
.primary-text {
	color: $primary;
}

.secondary-text {
	color: $secondary-text;
}

.error-message {
	color: $danger-red;
}

.dark-text {
	color: $main-text;
}

.green-text {
	color: $green;
}

.error-text {
	color: $danger-red;
}

.green-theme-text {
	color: $green-theme-bg;
}

.white-text {
	color: $white;
}

.crimson-text {
	color: $crimson;
}

.dark-blue-text {
	color: $dark-blue;
}

.primary-background {
	background: $primary;
}

.light-green-background {
	background: $light-green;

	&.round {
		border-radius: $border-radius-extra-large;
	}
}

.very-light-green-background {
	background: $primary-very-light;
}

.gray-background {
	background: $gray-theme-bg-dark;
}

.green-theme-background {
	background: $green-theme-bg;
}

.light-green-theme-background {
	background: $green-theme-bg-light;
}

.blue-theme-background {
	background: $blue-theme-bg;

	&-opaque {
		background: rgba(255, 255, 255, 0.1);
	}
}

.dark-blue-background {
	background: $dark-blue;
}

.navy-blue-background {
	background: $navy-blue;
}

.white-background {
	background: $white;

	&-opaque {
		background: rgba(255, 255, 255, 0.1);
	}
}

.black-background {
	background: $black;
}

.crimson-background {
	background: $crimson;
}

.light-gray-background {
	background: $gray-theme-bg-light;
}
.disabled {
	opacity: 0.6;
	cursor: not-allowed;

	.table-info {
		cursor: not-allowed;
	}
}

.green-theme-outline {
	outline: 2px solid $green-theme-bg;
}

.primary-outline {
	outline: 2px solid $primary;
}

.dark-blue-outline {
	outline: 2px solid $dark-blue;
}

.info-box {
	background: $gray-theme-bg;
	border-radius: $border-radius-main;

	&-secondary {
		background: $green-theme-bg-light;
		border-radius: $border-radius-main;
	}
	&-ternary {
		background: $blue-theme-bg-light;
		border-radius: $border-radius-main;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.box-shadow {
	box-shadow: $box-shadow-button;
}
/* Header Menu Transition */
.app-header {
	@include transition($basic-transition-time, ease);
}

/* Header Menu Position fix */
.app-header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2;
}

.truncate-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.truncate-table {
		max-width: 2.8rem;
	}
}
.truncate-table-text {
	display: inline-block;
	max-width: 30rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Logo Responsive Position fix */
.responsive-logo {
	position: absolute;
	right: calc(50% - 49px);
	top: calc(50% - 12px);
}

/* Active Business */
[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .sidebar-business-container .menu-item .menu-link.active {
	color: $primary;
	background: $primary-light;
}

/* Sidebar Menu Indentation in multiple layers */
.menu-item .menu-item .menu-item {
	padding-left: 1rem;
}

/* Tabol Logo menu position fix */
[data-kt-app-sidebar-minimize='on'] .app-sidebar-logo {
	justify-content: center;
}

[data-kt-app-sidebar-minimize='on'][data-kt-app-layout='dark-sidebar'] .app-sidebar:hover .app-sidebar-logo {
	justify-content: start;
}

.disabled-element {
	cursor: not-allowed;
	opacity: 0.7;
}
//============================//
//           MODAL           //
//===========================//
.modal-header-image {
	padding: 0 1rem;
	max-height: 20px;
}

.modal-content {
	box-shadow: none;
}

.modal-invisible {
	opacity: 0;
}

//============================//
//           TABER            //
//============================//
.nav {
	border: none;

	.nav-item {
		.nav-link {
			color: $black;
			font-weight: bold;
			margin: 0 1rem;

			&.active,
			&:hover:not(.disabled) {
				color: $primary;
				border-bottom: 2px solid $primary;
			}
		}
	}
}

//============================//
//     CUSTOM RADIO BUTTON    //
//============================//

/***** Container Wrapper *****/
.blocking-container {
	background: $white;
	border: 1px dashed $gray-theme-bg-dark;
	border-radius: $border-radius-main;
	max-height: 300px;
	overflow-y: auto;
}

.action-container {
	background: $white;
	border: 1px dashed $primary;
	border-radius: $border-radius-main;

	&.checked {
		background: $primary-light;
	}
}

/*** Custom Radio Checkbox ***/
.checkbox-wrapper {
	display: flex;
	position: relative;
}

.custom-checkbox {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;

	&:checked + .checkbox-label {
		background-color: $primary;
	}

	&:checked + .checkbox-label:after {
		content: '';
		position: absolute;
		top: 12px;
		left: 12px;
		transform: translate(-50%, -50%);
		width: 12px;
		height: 12px;
		border-radius: $border-radius-circle;
		background-color: $white;
	}
}

.checkbox-label {
	display: flex;
	width: 24px;
	height: 24px;
	border-radius: $border-radius-circle;
	background-color: $gray-theme-bg;

	span {
		padding-left: 2.5rem;
	}
}

/*** Custom Radio Checkbox ***/
.form-check-custom.form-check-solid .form-check-input.large-checkbox {
	width: 3rem;
	height: 3rem;

	&:checked {
		background-color: $green-theme-bg;
		border: 1px solid $white;
	}
}

//============================//
//       CUSTOM SELECT        //
//============================//

.custom-select,
.select-wrapper {
	position: relative;
	display: flex;
	width: 100%;
}
.form-select.form-select-solid,
.form-control.form-control-solid {
	background: $gray-theme-bg;
}
// .select-wrapper {
// 	overflow: hidden;
// }

.custom-select.disabled .select-wrapper {
	cursor: not-allowed;

	* {
		pointer-events: none;
		opacity: 0.6;
	}
}

.custom-select-icon {
	background: $gray-theme-bg;
	padding: 10px 15px;

	&.icon-left {
		border-radius: $border-radius-left;
		border-right: 1px solid $gray-theme-bg-dark;
	}

	&.icon-right {
		border-radius: $border-radius-right;
		border-left: 1px solid $gray-theme-bg-dark;
	}
}

.select-header {
	cursor: pointer;
	display: flex;
	align-items: center;
	border: none;
	border-radius: $border-radius-main;
	background-color: $gray-theme-bg;
	width: 100%;

	&.icon-left {
		border-radius: $border-radius-right;
	}

	&.icon-right {
		border-radius: $border-radius-left;
	}

	&.transparent-input {
		background-color: $transparent;
		border: 1px solid $gray-theme-bg;

		&:focus {
			background-color: $transparent;
		}
	}
}

.arrow-icon {
	@include rotate(0);
	font-size: 0.8rem !important; // to overwrite bootstrap class
}

.open .arrow-icon {
	@include rotate(180);
}

.selected-option {
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.select-options {
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
	width: 100%;
	max-height: 200px;
	overflow-y: auto;
	padding: 0;
	margin-top: 4px;
	background-color: $gray-theme-bg;
	// border: 1px solid $gray-theme-bg;
	border-radius: $border-radius-main;
	list-style: none;
}

.option {
	padding: 0.8rem 1.6rem;
	cursor: pointer;
	color: $primary;

	&.active {
		background-color: $primary;
		color: $primary-light;

		&:hover {
			background-color: $primary;
			color: $primary-light;
		}
	}
	&:hover {
		background-color: $primary-light;
		color: $primary;
	}
}

.hidden-select {
	display: none;
}

.section-border {
	border: 1px solid $secondary;
}

/***** New Select *****/
.select-container {
	background-color: $gray-theme-bg;
	border-radius: $border-radius-main;
	position: relative;
	z-index: 1;

	select {
		width: 100%;
		border: none;
		background: transparent;
		color: $secondary-text;
		cursor: pointer;
		outline: none;
		padding: 1rem 1.6rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.arrow-icon {
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0.8rem;
		z-index: -1;
	}

	.disabled {
		opacity: 0.6 !important;
		cursor: not-allowed !important;
	}
}

/***** Language Dropdown *****/
.dropdown-position {
	top: 4rem;
	left: -1rem;
}
/***** Overwrite Border *****/
.custom-date-picker {
	border: none;
	background: $gray-theme-bg;
	height: auto;
	padding: 0;

	&:focus {
		background: $gray-theme-bg;
	}
}

//============================//
//       CUSTOM INPUT         //
//============================//

.input-group {
	display: block;

	&.icon-input {
		display: flex;
		width: 100%;
		position: relative;
	}

	&.transparent-input {
		input.form-control {
			background-color: $transparent;
			border: 1px solid $gray-theme-bg;

			&:focus {
				background-color: $transparent;
			}
		}
	}

	input.form-control {
		width: 100%;
		background-color: $gray-theme-bg;
		border: none;
		border-radius: $border-radius-main;
		color: $secondary-text;

		&:focus {
			background-color: $gray-theme-bg;
		}
	}
}

/***** Overwrite Border *****/
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	border-top-left-radius: $border-radius-main;
	border-bottom-left-radius: $border-radius-main;
	margin-left: 0;
}

.custom-input-icon {
	background: $gray-theme-bg;
	padding: 10px 15px;

	&.icon-left {
		border-radius: $border-radius-left;
		border-right: 1px solid $gray-theme-bg-dark;
	}

	&.icon-right {
		border-radius: $border-radius-right;
		border-left: 1px solid $gray-theme-bg-dark;
	}
}

/***** Overwrite Switch to fix animation effect *****/
.form-switch .form-check-input {
	transition: background-position $short-transition-time ease-in-out;
}

.form-check-custom:not(.form-switch) .form-check-input {
	width: 1.7rem;
	height: 1.7rem;

	&:checked + label {
		color: $main-text;
	}
}

/***** Hide Scrollbar *****/
.nav-tabs::-webkit-scrollbar {
	display: none; /* Hides the scrollbar in Webkit browsers */
}
.nav-tabs {
	-ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
	scrollbar-width: none; /* Hides scrollbar in Firefox */
}

//============================//
//           ICONS            //
//============================//
.search-icon {
	content: '\f002';
	position: absolute;
	top: 17px;
	z-index: 1;
	left: 1.25rem;
	font-size: 1.2rem;
}

.input-search {
	i[class^='fa-'],
	i[class*=' fa-'] {
		line-height: 0;
	}
}

//============================//
//          BUTTONS           //
//============================//
.generic-button {
	@include button($primary, none, $green-theme-bg-light);

	&.active {
		background-color: $primary;
		color: $primary-light;

		&:hover {
			background-color: $primary-dark;
			color: $primary-light;
		}
	}

	&:hover {
		background: $primary-light;
		color: $primary;
	}
}

.primary-button {
	@include button($white, none, $primary);

	&:hover {
		background: $primary-dark;
		color: $white;
	}
}

.secondary-button {
	@include button($primary, none, $primary-light);

	&:hover {
		background: $primary;
		color: $primary-light;
	}
}

.delete-button {
	@include button($danger-red, none, $danger-light);
}

.white-button {
	@include button($black, 1px solid $black, $white);
}

.black-button {
	@include button($white, 1px solid $black, $black);
}

.gray-button {
	@include button($secondary-text, none, $gray-theme-bg);

	&:hover {
		background: $gray-theme-bg;
		color: $secondary-text;
	}
}

//============================//
//      LANGUAGE ICONS        //
//============================//
.flag-image-container {
	border: 1px solid $gray-theme-bg;
	border-radius: $border-radius-main;
	cursor: pointer;

	&:not(:first-child) {
		margin-left: 5px;
	}

	&.active,
	&:hover {
		background: $gray-theme-bg-dark;
	}

	.flag-image {
		max-width: 14px;
		margin-right: 4px;
	}

	span {
		color: $black;
	}
}

//============================//
//          UPLOADER          //
//============================//

.uploader-container {
	background: $gray-theme-bg;
	border: 1px dashed $primary;
	border-radius: $border-radius-small;

	&.green-background {
		background: $green-theme-bg-light;
	}

	.uploader-wrapper {
		display: flex;
		position: relative;

		.custom-checkbox {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
		}

		.uploader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			cursor: pointer;

			.uploader-text {
				display: flex;
				flex-direction: column;
			}
		}
	}
}

//============================//
//          RC SLIDER         //
//============================//

.rc-tooltip {
	background-color: $black !important;
	border-radius: $border-radius-main;
}

.rc-tooltip-inner {
	color: $white !important;
	font-weight: bold;
	background-color: $black !important;
	border: none !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
	left: calc(50% + 6px);
	display: none;
}

.rc-slider-mark-text {
	top: -3rem;
	color: $main-text !important;
	font-weight: bold;
}

.rc-slider-dot {
	display: none;
}

//============================//
//    THREE STATE CHECKBOX    //
//============================//

.checkbox-container {
	display: block;
	position: relative;
	padding: 3px 0 0 30px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		/* When the checkbox is checked, add a blue background */
		&:checked ~ .checkmark {
			background-color: $primary;

			/* Show the checkmark when checked */
			&:after {
				display: block;
			}
		}
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 1.55rem;
		width: 1.55rem;
		background-color: $green-theme-bg-light;
		border-radius: $border-radius-main;

		/* Create the checkmark/indicator (hidden when not checked) */
		&:after {
			content: '';
			position: absolute;
			display: none;
			left: 7px;
			top: 3px;
			width: 6px;
			height: 10px;
			border: solid white;
			border-width: $custom-checkmark-tick;
			@include rotate(45);
		}

		/* Third state checkbox (at least one entity) */
		&.select-items:after {
			left: 4px;
			top: 0;
			width: 11px;
			height: 11px;
			border-width: $custom-checkmark-dash;
			@include rotate(0);
		}
	}
}

//============================//
//          FILTERS           //
//============================//
.filter-container {
	position: absolute;
	z-index: 1;
	background: $white;
	max-width: 25rem;
	border-radius: $border-radius-main;
	box-shadow: $box-shadow-filters $white-very-light;
	opacity: 1;
	@include transition($basic-transition-time, ease-in-out);
}

// // Overwritting Datepicker class
// .react-datepicker__input-container,
// .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
// 	flex-grow: 1;
// }

//============================//
//        COLOUR CIRCLE       //
//============================//
.circle-primary {
	@include colored-circle($primary);
}

.circle-white {
	@include colored-circle($white);
}

.circle-black {
	@include colored-circle($black);
}

.circle-success {
	@include colored-circle($success-green);
}

.circle-warning {
	@include colored-circle($warning-yellow);
}

.circle-danger {
	@include colored-circle($danger-red);
}

.circle-green {
	@include colored-circle($green-theme-bg);
}

.circle-gray {
	@include colored-circle($gray-theme-bg);
}

.circle-blue {
	@include colored-circle($blue-theme-bg);
}

.circle-purple {
	@include colored-circle($purple-theme-bg);
}

//============================//
//        CUSTOM TABER        //
//============================//
.nav.nav-pills .nav-item {
	margin-right: 0;

	.nav-link {
		color: $secondary-text;
		margin: 0;
		padding: 0.8rem 3.2rem;
	}
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background: $green-theme-bg-light;
}

/***** Main Container *****/
.main-container {
	background-color: $white;

	.nav .nav-item .nav-link.active,
	.nav .nav-item .nav-link:hover:not(.disabled) {
		color: $primary;
		border: none;
		outline: 0;
	}

	&-header {
		border-radius: $border-radius-bottom;
		background-color: $white;
	}

	&-body {
		background-color: $white;
	}

	&-footer {
		border-radius: $border-radius-top;
		background-color: $white;
	}

	&.rounded {
		border-radius: $border-radius-large !important;
	}
}

/***** Circle Container *****/
.circle-container {
	background: $green-theme-bg-light;
	border-radius: $border-radius-circle;
	width: 80px;
	height: 80px;
}

.circle-border {
	background: $transparent;
	border-radius: $border-radius-circle;
	width: 40px;
	height: 40px;
	border: 1px solid $primary;
} /***** Notification Container *****/
.notification-container {
	background-color: $primary-light;
	border-radius: $border-radius-main;
}
.table-tab {
	border: none;
	background: transparent;
	color: $secondary-text;

	&.active {
		color: $primary;
		border-bottom: 1px solid $primary;
	}

	&:hover {
		color: $primary;
	}
}

//============================//
//      CUSTOM DROPDOWN       //
//============================//
.dropdown-toggle::after {
	content: none;
}

.dropdown-toggle i.bi,
.dropdown-toggle i[class^='fonticon-'],
.dropdown-toggle i[class*=' fonticon-'],
.dropdown-toggle i[class^='fa-'],
.dropdown-toggle i[class*=' fa-'],
.dropdown-toggle i[class^='la-'],
.dropdown-toggle i[class*=' la-'],
.dropdown-item i.bi,
.dropdown-item i[class^='fonticon-'],
.dropdown-item i[class*=' fonticon-'],
.dropdown-item i[class^='fa-'],
.dropdown-item i[class*=' fa-'],
.dropdown-item i[class^='la-'],
.dropdown-item i[class*=' la-'],
.tab-pane i[class^='fa-'] {
	color: $primary;
	font-size: 0.8rem;
}

.dropdown-item:hover,
.dropdown-item:focus {
	background-color: $primary-light;
	color: $primary;
}

.dropdown-item.primary-text {
	&:hover {
		color: $primary;
	}
}
.dropdown-item.error-text {
	&:hover,
	&:focus {
		color: $danger-red;
		background: $danger-light;
	}
}
.dropdown-item.secondary-text {
	&:hover,
	&:focus {
		color: $secondary-text;
		background: $gray-theme-bg;
	}
}

.dropdown-menu li {
	position: relative;
}

.dropdown-menu .dropdown-submenu {
	display: none;
	position: absolute;
	left: 100%;
	top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
	right: 100%;
	left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
	display: block;
}

.dropdown-item.no-focus:active {
	color: inherit;
	background-color: white;
}

//============================//
//       ROLES ACCORDION      //
//============================//
.main-container {
	.accordion-button {
		&:not(.collapsed) {
			background: $white;
		}
		&::after {
			background-image: var(--bs-accordion-btn-active-icon) !important;
		}
		&:focus {
			outline: none;
		}
	}
}

//==============================================================//
//                         REPORTS       	                      //
//==============================================================//
.sidebar,
[data-kt-app-sidebar-minimize='on'] .sidebar {
	position: fixed;
	top: 70px;
	background: $primary;
	height: calc(100% - 70px);
	z-index: 5;
	max-width: 80px;
	left: 75px;
	@include transition($basic-transition-time, ease);

	.nav-item {
		max-width: 80px;

		& > :hover,
		& > .active {
			max-width: 80px;
			background: $primary-dark;
		}
	}

	&.expanded {
		left: 265px;
		@include transition($basic-transition-time, ease);
	}
}

.main-content {
	margin-left: 80px;
}

.report-files {
	&:hover {
		background: $green-theme-bg-light;

		&.round {
			border-radius: 0 0 $border-radius-large $border-radius-large;
		}
	}
}

.settings-option.active .report-files {
	background: $green-theme-bg-light;
}

//============================//
//         CUSTOM TAG         //
//============================//
.custom-tag {
	background-color: $gray-theme-bg-dark;
	color: $secondary-text;
	border-radius: $border-radius-main;

	&.active {
		background-color: $primary-light;
		color: $primary;

		.close-button::before,
		.close-button::after {
			background-color: $primary;
		}
	}
}

.close-button {
	position: relative;
	width: 0.8rem;
	height: 0.8rem;
	background-color: transparent;
	cursor: pointer;

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: $secondary-text;
		top: 50%;
		transform-origin: 50% 50%;
	}

	&::before {
		@include rotate(45);
	}

	&::after {
		@include rotate(45);
	}
}

//============================//
//        SLIDE BUTTON        //
//============================//
.slide-toggle-button {
	cursor: pointer;
	width: 80px;
	overflow: hidden;
	background-color: $primary-light;
	border-radius: $border-radius-main;

	.left-side,
	.right-side {
		flex: 1;
		border-radius: 0;
		@include transition($basic-transition-time, ease);

		&.active {
			background-color: $primary;
			@include transition($basic-transition-time, ease);
		}
	}
}

//========================================================//
//            CUSTOM TABER (Columns & Filters)            //
//========================================================//
.taber-header {
	border-bottom: 1px solid $gray-theme-bg-dark;
	cursor: pointer;

	&.active {
		color: $primary;
		border-bottom: 1px solid $primary;
	}
}

.column-option {
	.close-button {
		display: none;

		&::before,
		&::after {
			background-color: $primary;
		}
	}

	&:hover {
		background: $green-theme-bg-light;

		.close-button {
			display: flex;
		}
	}
}

.drag-bar-container {
	cursor: pointer;

	.drag-bar {
		width: 1.6rem;
		height: 0.2rem;
		background: $gray-theme-bg-dark;
	}
}

.add-new-button,
.open-modal-button,
.edit-button,
.table-info,
.info-options {
	cursor: pointer;
}

.clear-all {
	cursor: pointer;
	top: 0;
	right: 1.5rem;
}

.gray-container {
	background: $gray-theme-bg;
}

// Data Tables Sticky Column
.sticky-column {
	position: sticky;
	right: 0;
	z-index: 0;

	&:has(.dropdown-menu.show) {
		z-index: 1;
	}
}

tr:has(.sticky-column .show.dropdown) {
	position: relative;
	z-index: 1;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
	background: #f8fafb;
}
.table-striped > tbody > tr:nth-of-type(even) > *,
.table-striped thead > tr > :last-child {
	background: $white;
}

// Data Table inside Modal Border
.table-border {
	border: 1px solid $border-color-gray;
}

.table.table-row-bordered tr.clickable-row {
	// cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		td {
			background: $gray-theme-bg-dark;
		}
	}
}

.clickable-row {
	&:hover {
		td {
			background: $gray-theme-bg-dark;
		}
	}
}

//============================//
//            CHAT            //
//============================//
.chat-body {
	height: calc(100vh - 225px);
	overflow-y: auto;
}

.chat-input {
	bottom: 0;
	left: 345px;
	width: calc(100vw - 345px);
	background: $white;
	border-radius: $border-radius-main;
	@include transition($basic-transition-time, ease);

	&-field {
		border: none;
		background: $gray-theme-bg;

		&:focus {
			outline: 0;
		}
	}

	&.expanded {
		left: 155px;
		width: calc(100vw - 155px);
		@include transition($basic-transition-time, ease);
	}

	.voice-message {
		left: -2.7rem;
		cursor: pointer;
	}
}

.bot-container-avatar {
	width: 32px;
	height: 32px;
	border-radius: $border-radius-circle;
	background: $dark-blue;
}

.bot-container-message,
.chat-container-message {
	max-width: 30rem;
	border-radius: $border-radius-extra-large;
	color: $white;
}

.bot-container-message {
	background: $dark-blue;
}

.chat-container-message {
	background: $primary;
}

//============================//
//      PLATFORMS CARDS       //
//============================//
.flip-card {
	background-color: transparent;
	height: 200px;
	perspective: 1000px;

	&-inner {
		@include transition($greater-transition-time, linear);
		transform-style: preserve-3d;
	}

	&:hover .flip-card-inner {
		transform: rotateY(180deg);
	}

	&-front,
	&-back {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		border-radius: $border-radius-main;
	}

	&-front {
		background: $white;

		img {
			max-width: 60px;
		}
	}

	&-back {
		background: $primary;
		transform: rotateY(180deg);
	}
}

.generic-card {
	height: 200px;
	background: $primary-light;
	border-radius: $border-radius-main;
	border: 1px solid $primary;
	cursor: pointer;

	img {
		max-width: 60px;
	}
}

//========================================================//
//                       USER ROLES                       //
//========================================================//

.user-roles {
	word-wrap: break-word;
	white-space: pre-wrap;
	margin-top: 1rem;
	font-family: inherit;
}
//========================================================//
//                        HOST VIEW                       //
//========================================================//

.host-view {
	background: var(--kt-app-bg-color);
}

.toggle-sidebar {
	cursor: pointer;
	left: -20px;
	top: 18px;
	max-width: 34px;
	box-shadow: $box-shadow-button;
}

.hostview-sidebar {
	@include transition($basic-transition-time, linear);
}

#hostview-main-content {
	right: 0;
	@include transition($basic-transition-time, linear);
}

.slide-left {
	@include translate(-100%, 0);
}

.slide-right {
	@include transition($basic-transition-time, linear);
	.toggle-sidebar {
		left: 0;
		z-index: 1;
	}
}

.transition {
	@include transition;
}

.reservation-box-container {
	cursor: pointer;
	transform: translateX(0rem);
	@include transition($basic-transition-time, linear);
}
.reservation-box-info {
	max-width: calc(100% - 5.2rem);
}
.slide {
	transform: translateX(-14rem);
	@include transition($basic-transition-time, linear);

	&.half {
		transform: translateX(-7rem);
		@include transition($basic-transition-time, linear);
	}
	.status-box-container {
		display: flex;
	}
}

.slide-action {
	transform: translateX(0);
	@include transition($basic-transition-time, linear);

	&.active {
		transform: translateX(105px);
		transition: all 0.3s linear;
	}
}

.status-box-container {
	.status-box {
		cursor: pointer;
		width: 7rem;

		&.edit,
		&.waitlist {
			background: $green-theme-bg-light;
		}
		&.confrim,
		&.completed {
			background: $green-theme-bg;
		}
		&.arrived {
			background: $black;
		}
		&.reserve {
			background: $primary;
		}
		&.decline {
			background: $danger-red;
		}
	}
}

.info-options {
	position: absolute;
	width: calc(100% - 0.75rem);
	background-color: $white;
	left: 0.375rem;
	right: 0;
	z-index: 1;
	border-radius: $border-radius-large;
	box-shadow: $box-shadow-tables rgba(0, 0, 0, 0.25);

	&.bottom {
		margin-top: 0.75rem;
	}
	&.top {
		top: -12rem;
	}
}

/* Custom React Datepicker */
.react-datepicker-wrapper {
	display: flex !important;
}
.react-datepicker {
	border: none !important;
	font-size: 1rem !important;
}

.react-datepicker-popper {
	z-index: 5 !important;
}

.react-datepicker__header {
	background-color: $white !important;
	border-bottom: 1px solid $primary !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
	&::after {
		border-bottom-color: $white !important;
	}
	&::before {
		border-bottom-color: $primary !important;
	}
}

.react-datepicker__current-month,
.react-datepicker__day-name {
	color: $black !important;
}

.react-datepicker__day-name {
	margin: 0.6rem !important;
}

.react-datepicker__navigation-icon::before {
	border-color: $primary !important;
	top: 10px !important;
}

.react-datepicker__day--selected {
	background: $primary !important;
	border-radius: $border-radius-circle !important;

	&.react-datepicker__day {
		&:hover {
			background: $primary-dark !important;
			color: $white !important;
		}
	}
}

.react-datepicker__day {
	border-radius: $border-radius-circle !important;
	margin: 0.3rem !important;
	width: 2.2rem !important;
	height: 2.2rem !important;
	line-height: 2.2rem !important;

	&:hover {
		background: $primary-light !important;
		color: $primary;
	}
}

//========================================================//
//                      MEDIA QUERIES                     //
//========================================================//
/* Extra large devices (large desktops) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (desktops) */
@media only screen and (max-width: 992px) {
	[data-kt-app-sidebar-minimize='on'] .sidebar,
	.sidebar {
		top: 61px;
		height: calc(100% - 61px);
		left: 0;

		&.expanded {
			left: 0;
		}
	}

	.chat-body {
		height: calc(100vh - 206px);
	}

	.chat-input {
		left: 80px;
		width: calc(100vw - 80px);
	}

	.padding-top-fix {
		padding-top: 60px;
	}

	// Responsive Right Menu
	.custom-drawer {
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		width: 225px;
		background-color: $white;
		z-index: 10;
		transform: translateX(100%);
		transition: transform $basic-transition-time ease-in-out;

		&.open {
			transform: translateX(0);
		}
		&.no-transition {
			transition: none;
		}
	}

	.drawer-overlay {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $dark-overlay;
		z-index: 9;
	}
}

/* Medium devices (desktops) */
@media only screen and (max-width: 768px) {
	.info-options {
		position: relative;
		width: 100%;
		left: 0;
	}
}

/* Small devices (tablets) */
@media only screen and (max-width: 576px) {
	.button-truncated {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		appearance: none;
		max-width: 10rem;
	}
	.add-button-padding {
		padding: 0.5rem 1.2rem;
	}
}
