//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

//============================//
//           FOOTER           //
//============================//

.footer-item {
	padding-bottom: 0.5rem;
}

.footer-title {
	color: #9d9da6;

	&:hover,
	&:active {
		color: var(--kt-primary-inverse);
	}
}

.custom-list {
	list-style-type: none;
	padding-left: 0;

	li::before {
		content: '-';
		margin-right: 0.5em;
	}
}

.span-add-item {
	&:hover,
	&:active {
		cursor: pointer;
	}
}

.sidebar-seperator {
	border-bottom: 1px dashed #393945;
}

//============================//
//             SVG            //
//============================//

.btn .svg-icon {
	margin-right: 0;
}

.custom-date-picker {
	height: 38px; /* Adjust based on the height of the other dropdowns */
	border-radius: 0.25rem; /* Match the border radius of the other dropdowns */
	border: 1px solid #ced4da; /* Typical Bootstrap form control border */
}

.input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: none; /* Remove the right border to make it blend with the date picker */
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
	display: block;
	// width: 92%;
	flex-grow: 1;
}

.react-datepicker-popper .react-datepicker__children-container {
    width: 100%;
    margin: 0;
    padding-inline: 1rem;
    padding-bottom: 1rem;
}

button[disabled],
button.disabled {
	pointer-events: none;
	user-select: none;
}