//
// Reboot
//

html {
	font-family: sans-serif;
	text-size-adjust: 100%;
}

html,
body {
	height: 100%;
	margin: 0px;
	padding: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: $app-general-root-font-size-desktop !important;
	font-weight: $font-weight-base;
	font-family: $font-family-sans-serif;

	// Tablet mode
	@include media-breakpoint-down(lg) {
		font-size: $app-general-root-font-size-tablet !important;
	}

	// Mobile mode
	@include media-breakpoint-down(md) {
		font-size: $app-general-root-font-size-mobile !important;
	}
}

body {
	display: flex;
	flex-direction: column;

	a:hover,
	a:active,
	a:focus {
		text-decoration: none !important;
	}
}

canvas {
	user-select: none;
}

// Angular integration
router-outlet {
	display: none;
}

// Layouts
body,
.app-default {
	background-color: var(--kt-app-bg-color);
}

.app-auth-default {
	background-color: var(--kt-app-auth-bg-color) !important;
}

.app-blank {
	background-color: var(--kt-app-blank-bg-color);
}

// Reset CSS3 Transitions for child elements
[data-kt-app-reset-transition='true'] * {
	transition: none !important;
}
