//
// Slider
//

// Base
.tns {
	position: relative;
	overflow: hidden;

	[data-tns='true'] {
		display: none;
	}

	.tns-item {
		opacity: 0;
		transition: all 0.3s ease;
	}

	.tns-controls {
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			outline: none;
			border: 0;
			margin: 0 0.25rem;
			@include border-radius($btn-border-radius);
			padding: 0.5rem 0.75rem;
			background-color: var(--kt-primary);
			color: var(--kt-primary-inverse);

			&:hover {
				background-color: var(--kt-primary-active);
			}
		}
	}

	.tns-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;

		button {
			display: block;
			outline: none;
			width: 1.25rem;
			height: 0.75rem;
			background-color: var(--kt-gray-200);
			margin: 0 0.25rem;
			border: 0;
			@include border-radius(0.35rem);

			&.tns-nav-active {
				background-color: var(--kt-primary);
			}
		}
	}

	&.tns-initiazlied {
		[data-tns='true'] {
			display: flex;
		}

		.tns-item {
			opacity: 1;
			transition: all 0.3s ease;
		}
	}

	// Default
	&.tns-default {
		position: relative;

		// Navs
		[data-controls='prev'],
		[data-controls='next'] {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		[data-controls='prev'] {
			left: 0;
		}

		[data-controls='next'] {
			right: 0;
		}

		.tns-outer {
			margin: 0 4rem;

			// Tablet mode
			@include media-breakpoint-down(md) {
				margin: 0 2rem;
			}
		}
	}
}

// Utils
.tns-hide-disabled-nav {
	[disabled] {
		display: none !important;
	}
}
