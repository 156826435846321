//
// FormValidation Customization.  More info: https://formvalidation.io/
//

.fv-plugins-message-container {
	margin-top: 0.3rem;

	.fv-help-block {
		color: var(--kt-danger);
		font-size: 1rem;
		font-weight: $font-weight-normal;
	}

	&.valid-feedback,
	&.invalid-feedback {
		display: block;
		font-weight: $font-weight-normal;
	}
}

.transitionDiv {
	background: red;
	margin: 0 auto;
}

@keyframes inAnimation {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes outAnimation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}
