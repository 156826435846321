//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

//========================================================//
//                      COLORS                            //
//========================================================//

// Main Colors //
$primary: #40798c;
$primary-light: #ecf2f4;
$primary-dark: #2f5e6e;

// Theming Colors //
$green-theme-bg: #70a9a1;
$green-theme-bg-light: #f1f6f6;
$gray-theme-bg: #f9f9f9;
$gray-theme-bg-dark: #ededed;
$gray-theme-bg-light: rgba(11, 32, 39, 0.1);
$blue-theme-bg: #41558c;
$blue-theme-bg-light: #eceef4;
$purple-theme-bg: #8861ba;
$purple-theme-bg: #f3eff8;
$dark-blue: #0b2027;
$green: #47be7c;
$light-green: #e8fff3;
$crimson: #a80029;
$navy-blue: #3b7082;

// Bootstrap Main Colors //
$white: #ffffff;
$black: #000000;
$success-green: #118943;
$danger-red: #f1416c;
$danger-light: #fff5f8;
$warning-yellow: #ffc700;

// Ratings Colors //
$primary-star: $primary;
$blue-star: #012c61;
$red-star: #c60251;
$light-blue-star: #2784ef;

// Border Colors //
$border-color-gray: #eff2f5;

// Transparent Colors //
$transparent: transparent;
$white-very-light: rgba(0, 0, 0, 0.15);
$dark-overlay: rgba(0, 0, 0, 0.5);
$primary-very-light: rgba(64, 121, 140, 0.1);
//========================================================//
//                      TYPOGRAPHY                        //
//========================================================//

$main-text: #373737;
$secondary-text: #727272;

//========================================================//
//                        DISTANCES                       //
//========================================================//

// Border Radius //
$border-radius-circle: 50%;
$border-radius-extra-large: 16px;
$border-radius-large: 10px;
$border-radius-main: 5px;
$border-radius-small: 3px;
$border-radius-bottom: 5px 5px 0 0;
$border-radius-top: 0 0 5px 5px;
$border-radius-left: 5px 0 0 5px;
$border-radius-right: 0 5px 5px 0;

// Checkbox Border //
$custom-checkmark-tick: 0 2px 2px 0;
$custom-checkmark-dash: 0 0 2px 0;

// Box Shadows //
$box-shadow-filters: 0 4px 12px 0;
$box-shadow-tables: 0 4px 4px 0;
$box-shadow-button: 0 0 4px 0 $white-very-light;

//========================================================//
//                          TIME                          //
//========================================================//

$short-transition-time: 0.15s;
$basic-transition-time: 0.3s;
$greater-transition-time: 0.6s;

////////////////// Utilities (Future use) //////////////////

// Primary
$primary: #40798c;
$primary-active: #2b6376;
$primary-light: #ecf2f4;
$primary-light-dark: #0b2027;
$primary-inverse: $white;

// Secondary
$secondary: #9eb0b8;
$secondary-active: #5b676c;
$secondary-light: #9cbbd0;
$secondary-light-dark: #0b2027;
$secondary-inverse: $white;

// Success
$success: #118943;
$success-active: #095328;
$success-light: #f1faff;
$success-light-dark: #0b2027;
$success-inverse: $white;
$action: $light-green;
$action-active: #3bae70;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;
