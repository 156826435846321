//
// Recaptcha
//

// Base
.recaptcha {
	padding: 15px;
	border: 1px solid var(--kt-gray-200);
	@include border-radius($border-radius);

	.recaptcha-img {
		margin-bottom: 10px;
	}

	.recaptcha_only_if_incorrect_sol {
		color: var(--kt-danger);
	}

	.input-group {
		.btn {
			i {
				padding-right: 0;
			}
		}

		.form-control {
			@include border-top-start-radius($border-radius !important);
			@include border-bottom-start-radius($border-radius !important);
		}
	}
}
