//
// Quill
//

// Base
.ql-toolbar {
	font-family: $font-family-sans-serif;

	&.ql-snow {
		border: 1px solid var(--kt-border-color);
		@include border-top-radius($border-radius);

		.ql-picker,
		button {
			.ql-fill,
			.ql-stroke {
				stroke: var(--kt-gray-500);
			}

			.ql-fill {
				fill: var(--kt-gray-500);
			}

			&.ql-expanded,
			&.ql-active,
			&:focus,
			&:hover {
				.ql-fill,
				.ql-stroke {
					stroke: var(--kt-primary);
				}

				.ql-fill {
					fill: var(--kt-primary);
				}
			}
		}
	}
}

.ql-editor {
	color: var(--kt-input-color);

	&.ql-blank {
		&:before {
			left: auto !important;
			right: auto !important;
			color: var(--kt-text-muted) !important;
			font-style: normal !important;
		}
	}
}

.ql-container {
	&.ql-snow {
		background-color: var(--kt-input-bg);
		border: 1px solid var(--kt-gray-200);
		border-top: 0;
		@include border-bottom-radius($border-radius);
	}
}

.ql-snow {
	.ql-picker {
		.ql-picker-label {
			color: var(--kt-gray-500);

			&.ql-active,
			&:hover {
				color: var(--kt-primary);
			}
		}

		&.ql-expanded {
			outline: none !important;
			border-color: transparent !important;

			.ql-picker-label {
				border-color: transparent !important;
				color: var(--kt-primary);
				outline: none !important;

				&.ql-active,
				&:hover {
					color: var(--kt-primary);
				}
			}

			.ql-picker-options {
				border: 0;
				padding: 0.5rem 1rem;
				box-shadow: var(--kt-dropdown-box-shadow);
				background-color: var(--kt-body-bg);
				@include border-radius($border-radius);

				.ql-picker-item {
					color: var(--kt-gray-600);
					outline: none;

					&.ql-selected,
					&.ql-active,
					&:hover {
						color: var(--kt-primary);
					}
				}
			}
		}
	}

	.ql-tooltip {
		border: 0;
		padding: 0.5rem 1rem;
		box-shadow: var(--kt-dropdown-box-shadow);
		@include border-radius($border-radius);

		input[type='text'] {
			@include input-reset();
			border: 1px solid var(--kt-border-color);
			color: $input-plaintext-color;
			outline: none !important;
			@include border-radius($border-radius);

			&:active,
			&:focus {
				border-color: var(--kt-input-focus-border-color) !important;
			}
		}

		.ql-preview {
			color: var(--kt-gray-600);
		}

		.ql-action {
			transition: color 0.3s ease;
			color: var(--kt-gray-600);

			&:hover {
				transition: color 0.3s ease;
				color: var(--kt-primary);
			}
		}

		// Tooltip adjustment in modal
		.modal & {
			&.ql-editing {
				left: 20px !important;
			}
		}
	}

	.ql-editor {
		pre.ql-syntax {
			background-color: var(--kt-gray-900);
			color: var(--kt-text-muted);
			overflow: visible;
			@include border-radius($border-radius);
		}
	}
}

// Plain Style
.ql-quil.ql-quil-plain {
	.ql-toolbar {
		padding: 0;
		margin: 0;
		border: 0;

		&:after {
			display: none;
		}

		.ql-picker-label {
			padding-left: 0;
		}
	}

	.ql-container {
		border: 0;
	}

	.ql-editor {
		border: 0;
		padding: 0;
	}
}
